import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Search,
  Building, 
  Home,
  ChevronDown,
  AlertCircle,
  LayoutDashboard,
  ClipboardList,
  CheckCircle
} from 'lucide-react';
import { Link } from 'react-router-dom';
import api from '../apiConfig/api';
import TradeLicenseSection from './tradelisencesection';
import { useSurvey } from '../context/surveycontext';
import ResponsivePropertyTable from '../components/propertytable';

// SelectField component remains exactly the same
const SelectField = ({ label, id, options, error, icon: Icon, className = '', ...props }) => (
  <div className={`space-y-2 relative group ${className}`}>
    <label htmlFor={id} className="block text-sm font-semibold text-gray-700 mb-1.5">
      {label}
    </label>
    <div className="relative">
      {Icon && (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Icon className="h-5 w-5 text-[#75d1e3] group-hover:text-[#5dbdd0] transition-colors" />
        </div>
      )}
      <select
        id={id}
        className={`w-full ${Icon ? 'pl-10' : 'pl-4'} pr-10 py-3.5 bg-white border-2 border-gray-200 
        rounded-xl focus:ring-2 focus:ring-[#75d1e3] focus:border-[#75d1e3] transition-all duration-200 
        hover:border-[#75d1e3] shadow-sm appearance-none text-black font-medium 
        disabled:bg-gray-50 disabled:cursor-not-allowed`}
        {...props}
      >
        <option value="" className="text-gray-500">Select {label}</option>
        {options?.map((option, index) => (
          <option 
            key={`${option.value}-${index}`} 
            value={option.value} 
            className="text-black font-medium"
          >
            {option.label}
          </option>
        ))}
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <ChevronDown className="h-5 w-5 text-gray-400" />
      </div>
    </div>
    {error && (
      <div className="absolute -bottom-6 left-0 flex items-center gap-2 text-red-500 text-sm">
        <AlertCircle size={14} />
        <span className="font-medium">{error}</span>
      </div>
    )}
  </div>
);

// Tab component remains exactly the same
const Tab = ({ active, icon: Icon, label, onClick }) => (
  <button
    onClick={onClick}
    className={`flex items-center gap-2 px-6 py-4 font-medium transition-all duration-200 border-b-2 ${
      active 
        ? 'text-[#75d1e3] border-[#75d1e3]' 
        : 'text-gray-500 border-transparent hover:text-[#75d1e3] hover:border-[#75d1e3]'
    }`}
  >
    <Icon className="h-5 w-5" />
    {label}
  </button>
);

const SurveyForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { zoneName: contextZoneName } = useSurvey();
  const assessmentInputRef = useRef(null);
  
  const routeParams = {
    orgId: location.state?.orgId,
    userId: location.state?.userId,
    zoneId: location.state?.zoneId,
    organizationName: location.state?.organizationName,
    zoneName: location.state?.zoneName || contextZoneName,
    orgCode: location.state?.orgCode  // Add this line
  };
  console.log(routeParams)

  // All state declarations
  const [activeTab, setActiveTab] = useState('pending');
  const [assessmentId, setAssessmentId] = useState('');
  const [assessmentSearchLoading, setAssessmentSearchLoading] = useState(false);
  const [formData, setFormData] = useState({
    ward_id: '',
    street_id: ''
  });
  const [wardStreetSearchLoading, setWardStreetSearchLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [userWards, setUserWards] = useState([]);
  const [userStreets, setUserStreets] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [wardAssessments, setWardAssessments] = useState({});
const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [orgName, setOrgName] = useState(routeParams.organizationName || '');
  const [streetLoading, setStreetLoading] = useState(false);
  const [streetError, setStreetError] = useState('');
  const [completedData, setCompletedData] = useState([]);
  const [completedDataLoading, setCompletedDataLoading] = useState(false);
  const [searchError, setSearchError] = useState('');
  const [assessmentSearchError, setAssessmentSearchError] = useState('');
  const [shouldAutoSearch, setShouldAutoSearch] = useState(false);
  const [searchMethod, setSearchMethod] = useState(null);
  const [wardsLoading, setWardsLoading] = useState(false);
  const [wardsError, setWardsError] = useState('');
  const [orgCode, setOrgCode] = useState(localStorage.getItem('org_code') || '');
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [zoneName, setZoneName] = useState('');
  const [streetData, setStreetData] = useState({});
  const [allProperties, setAllProperties] = useState([]);
  useEffect(() => {
    const storedZoneName = localStorage.getItem('zone_name');
    setZoneName(storedZoneName || 'No Zone');
  }, []);
  useEffect(() => {
    const fetchWards = async () => {
      setWardsLoading(true);
      setWardsError('');
  
      try {
        const wardDetailsStr = localStorage.getItem('ward_details');
        if (wardDetailsStr) {
          const wardDetails = JSON.parse(wardDetailsStr);
          const formattedWards = wardDetails.map(ward => ({
            value: ward.wardId.toString(),  // This ward ID will be used for the asset API
            label: ward.wardName
          }));
          setUserWards(formattedWards);
  
          // Check if we just came from verification and haven't navigated elsewhere
          const storedFormData = localStorage.getItem('formData');
          const fromVerification = sessionStorage.getItem('fromVerification');
          const lastPath = sessionStorage.getItem('lastPath');
          const currentPath = location.pathname;
          
          // Only populate if coming directly from verification
          if (storedFormData && fromVerification === 'true' && (!lastPath || lastPath === '/verify')) {
            const parsedFormData = JSON.parse(storedFormData);
            setFormData({
              ward_id: parsedFormData.ward_id,
              street_id: parsedFormData.street_id
            });
            setHasSearched(true);
            setShouldAutoSearch(true);
          }
  
          // Store current path
          sessionStorage.setItem('lastPath', currentPath);
          
          // Clear verification flags and data if not coming directly from verification
          if (!fromVerification || (lastPath && lastPath !== '/verify')) {
            clearStoredData();
          }
        } else {
          setWardsError('No ward data available');
          setUserWards([]);
        }
      } catch (error) {
        console.error('Error fetching wards:', error);
        setWardsError('Failed to load wards');
        setUserWards([]);
      } finally {
        setWardsLoading(false);
      }
    };
  
    fetchWards();
  
    // Cleanup function
    return () => {
      const fromVerification = sessionStorage.getItem('fromVerification');
      // Only clear data if not going to verification page
      if (!fromVerification) {
        clearStoredData();
      }
    };
  }, [location.pathname]);

  const clearStoredData = () => {
    localStorage.removeItem('formData');
    sessionStorage.removeItem('fromVerification');
    sessionStorage.removeItem('searchMethod');
    sessionStorage.removeItem('pendingStreetId');
  };

  useEffect(() => {
    const autoSearch = async () => {
      if (shouldAutoSearch && formData.ward_id && formData.street_id) {
        await fetchPropertyData();
        setShouldAutoSearch(false);
      }
    };
  
    autoSearch();
  }, [formData.ward_id, formData.street_id, shouldAutoSearch]);
  useEffect(() => {
    const storedOrgCode = localStorage.getItem('organization_code');
    if (storedOrgCode) {
      setOrgCode(storedOrgCode);
      setAssessmentId(`${storedOrgCode}/`);
    }
  }, []); 
  useEffect(() => {
    const fetchStreets = async () => {
      if (!formData.ward_id) {
        setUserStreets([]);
        return;
      }
  
      setStreetLoading(true);
      setStreetError('');
  
      try {
        const response = await api.get('/asset', {
          params: { ward_id: formData.ward_id }
        });
        
        if (response.data && Array.isArray(response.data.data)) {
          const formattedStreets = response.data.data.map(street => ({
            value: street.street_id.toString(),
            label: street.street_name
          }));
          
          setUserStreets(formattedStreets);
  
          if (formData.street_id && shouldAutoSearch) {
            setHasSearched(true);
            await fetchPropertyData();
          }
        } else {
          throw new Error('Invalid street data received');
        }
      } catch (error) {
        console.error('Error fetching streets:', error);
        setStreetError('No Streets found');
        setUserStreets([]);
      } finally {
        setStreetLoading(false);
      }
    };
  
    fetchStreets();
  }, [formData.ward_id]);

  const fetchAutocompleteResults = async (searchNum) => {
    if (!formData.ward_id) return;
    
    setAutocompleteLoading(true);
    try {
      const cachedAssessments = wardAssessments[formData.ward_id] || [];
      
      // Filter based on numeric part and return just the AssesmentNo for display
      const filteredResults = cachedAssessments
        .filter(assessment => {
          const numericPart = assessment.AssesmentNo.replace(/[^\d]/g, '').slice(6);
          return numericPart.includes(searchNum);
        })
        .map(assessment => assessment.AssesmentNo)
        .slice(0, 10);
      
      setAutocompleteResults(filteredResults);
      setShowAutocomplete(true);
    } catch (error) {
      console.error('Error filtering assessment numbers:', error);
      setAutocompleteResults([]);
    } finally {
      setAutocompleteLoading(false);
    }
  };
    const formatAssessmentId = (value) => {
      const numbers = value.replace(/[^\d]/g, '');
      
      if (numbers.length <= 3) {
        return `${orgCode}/`;
      }
    
      const postOrgCode = numbers.slice(3);
      
      // Add the forward slash immediately after the second group of 3 digits
      if (postOrgCode.length === 3) {
        return `${orgCode}/${postOrgCode}/`;
      }
      
      if (postOrgCode.length <= 3) {
        return `${orgCode}/${postOrgCode}`;
      }
      
      return `${orgCode}/${postOrgCode.slice(0, 3)}/${postOrgCode.slice(3)}`;
    };
// Replace the existing handleAssessmentIdChange function with this improved version
const handleAssessmentIdChange = (e) => {
  const input = e.target.value;
  const currentParts = assessmentId.split('/');
  const orgPrefix = currentParts[0];
  const wardNumber = currentParts[1] || '';
  
  // Only allow editing of the last part
  if (input.length < assessmentId.length) {
    // Handle backspace - don't allow deleting the first two parts
    const newValue = input.length <= 8 ? `${orgPrefix}/${wardNumber}/` : input;
    setAssessmentId(newValue);
    setShowAutocomplete(false);
    setAutocompleteResults([]);
    return;
  }

  // Only modify the last part of the assessment ID
  const lastPart = input.slice(8).replace(/[^\d]/g, '');
  if (lastPart.length <= 6) {
    const newAssessmentId = `${orgPrefix}/${wardNumber}/${lastPart}`;
    setAssessmentId(newAssessmentId);
    
    if (lastPart.length > 0) {
      setAutocompleteLoading(true);
      setShowAutocomplete(true);
      fetchAutocompleteResults(lastPart);
    } else {
      setShowAutocomplete(false);
      setAutocompleteResults([]);
    }
  }
};
  const clearForm = () => {
    setFormData({ ward_id: '', street_id: '' });
    setSearchResults([]);
    setCompletedData([]);
    setHasSearched(false);
    setActiveTab('pending');
    setAssessmentId(orgCode ? `${orgCode}/` : '');
    setAssessmentSearchError('');
    setSearchError('');
    setAutocompleteResults([]);
    setShowAutocomplete(false);
    localStorage.removeItem('formData');
    sessionStorage.removeItem('fromVerification');
    sessionStorage.removeItem('searchMethod');
    sessionStorage.removeItem('pendingStreetId');
    setShouldAutoSearch(false);
    setSearchMethod(null);
  };

  const clearStoredFormData = () => {
    localStorage.removeItem('formData');
    sessionStorage.removeItem('fromVerification');
    sessionStorage.removeItem('searchMethod');
    sessionStorage.removeItem('pendingStreetId');
  };

  

  const handleAssessmentSearch = async (e) => {
    e.preventDefault();
    
    const parts = assessmentId.split('/');
    if (!assessmentId.trim() || parts.length !== 3 || 
        parts[0].length !== 3 || parts[1].length !== 3 || parts[2].length !== 6) {
      setFormErrors({ assessmentId: 'Please enter a valid Assessment ID (XXX/XXX/XXXXXX)' });
      return;
    }
  
    setAssessmentSearchLoading(true);
    setAssessmentSearchError('');
    setSearchMethod('assessment');
  
    try {
      // First get the AssesmentID
      const assessmentData = await api.get('/asset/byref', {
        params: {
          ward_ids: userWards.map(ward => ward.value).join(','),
          asst_ref: assessmentId
        }
      });
  
      if (assessmentData.data?.data?.id) {
        navigate(`/verify/${assessmentData.data.data.id}`);
      } else {
        setAssessmentSearchError('No property details found for this Assessment ID');
      }
  
    } catch (error) {
      console.error('Search error:', error);
      setAssessmentSearchError('No property details found for this Assessment ID');
    } finally {
      setAssessmentSearchLoading(false);
    }
  };

  const handleWardStreetSearch = async (e) => {
    e.preventDefault();
    if (!formData.ward_id || !formData.street_id) {
      setFormErrors({
        ...((!formData.ward_id && { ward_id: 'Ward is required' })),
        ...((!formData.street_id && { street_id: 'Street is required' }))
      });
      return;
    }
    
    setAssessmentSearchError('');
    setFormErrors((prev) => ({ ...prev, assessmentId: '' }));
    setSearchMethod('ward-street');
    
    localStorage.setItem('formData', JSON.stringify({
      ward_id: formData.ward_id,
      street_id: formData.street_id,
      fromVerification: false
    }));
    
    setHasSearched(true);
    await fetchPropertyData();
  };
  
    // Inside your SurveyForm component, replace the existing handleWardChange with this:
    const handleWardChange = async (e) => {
      const newWardId = e.target.value;
      setFormData(prev => ({
        ...prev,
        ward_id: newWardId,
        street_id: ''
      }));
      
      setAssessmentSearchError('');
      setFormErrors((prev) => ({ ...prev, ward_id: '', assessmentId: '' }));
      
      // Clear previous results when ward changes
      setSearchResults([]);
      setHasSearched(false);
      
      // Get selected ward details and update assessment ID
      const selectedWard = userWards.find(ward => ward.value === newWardId);
      if (selectedWard) {
        const wardNumber = selectedWard.label.match(/\d+/)?.[0];
        if (wardNumber) {
          const formattedWardNumber = wardNumber.padStart(3, '0');
          setAssessmentId(`${orgCode}/${formattedWardNumber}/`);
        }
      }
    
      if (newWardId) {
        setIsInitialLoading(true);
        try {
          const response = await api.get(`/asset/assessments`, {
            params: { 
              orgid: routeParams.orgId,
              wid: newWardId
            }
          });
          
          if (response.data?.streetdata) {
            // Store the street data
            setStreetData(response.data.streetdata);
            
            // Format streets for dropdown
            const streets = Object.keys(response.data.streetdata).map(street => ({
              value: street,
              label: street
            }));
            setUserStreets(streets);
          }
    
          // Store assessment numbers for autocomplete
          if (response.data?.data) {
            setWardAssessments(prev => ({
              ...prev,
              [newWardId]: response.data.data
            }));
          }
        } catch (error) {
          console.error('Error fetching assessment numbers:', error);
          setStreetError('Failed to load streets');
          setUserStreets([]);
        } finally {
          setIsInitialLoading(false);
        }
      }
    };
    const handleStreetChange = (e) => {
      const selectedStreet = e.target.value;
      setFormData(prev => ({ ...prev, street_id: selectedStreet }));
      setAssessmentSearchError('');
      setFormErrors((prev) => ({ ...prev, street_id: '', assessmentId: '' }));
      
      if (selectedStreet && streetData[selectedStreet]) {
        // Format properties for the selected street
        const streetProperties = streetData[selectedStreet].map(prop => ({
          id: prop.AssesmentID,
          door_no: prop.DoorNo,
          owner: prop.OwnerName || 'N/A',
          asst_ref: prop.AssesmentNo,
          street_name: selectedStreet
        }));
        
        setSearchResults(streetProperties);
        setHasSearched(true);
      } else {
        setSearchResults([]);
        setHasSearched(false);
      }
    };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (hasSearched && formData.ward_id && formData.street_id) {
      fetchPropertyData(tab);
    }
  };

  const fetchPropertyData = async (currentTab = activeTab) => {
    const isCompleted = currentTab === 'completed';
    const loadingState = isCompleted ? setCompletedDataLoading : setWardStreetSearchLoading;
    const setData = isCompleted ? setCompletedData : setSearchResults;

    if (!formData.ward_id || !formData.street_id) {
      return;
    }

    loadingState(true);
    setSearchError('');

    try {
      const response = await api.get('/asset/', {
        params: {
          ward_id: formData.ward_id,
          street_id: formData.street_id,
          status: isCompleted ? 'Completed' : 'Active'
        }
      });

      if (response.data && Array.isArray(response.data.data)) {
        setData(response.data.data);
      } else {
        throw new Error('Invalid data format received');
      }
    } catch (error) {
      console.error('Error fetching properties:', error);
      setSearchError('No Properties Found');
      setData([]);
    } finally {
      loadingState(false);
    }
  };

  const renderTable = (data) => (
    <div>
      {/* Desktop Table View */}
      <div className="hidden sm:block w-full">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-100 rounded-lg">
              <th className="text-left font-semibold px-6 py-4 border-b">Assessment ID</th>
              <th className="text-left font-semibold px-6 py-4 border-b">Door Number</th>
              <th className="text-left font-semibold px-6 py-4 border-b">Owner Name</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id} className="border-b hover:bg-gray-50">
                <td className="px-6 py-4 align-middle">
                  <Link 
                    to={`/verify/${item.id}`}
                    state={{
                      orgid: routeParams.orgId,
                      wid: formData.ward_id,
                      asstid: item.id
                    }}
                    className="text-[#75d1e3] hover:text-[#5dbdd0] hover:underline cursor-pointer"
                  >
                    {item.asst_ref}
                  </Link>
                </td>
                <td className="px-6 py-4 align-middle">{item.door_no || 'N/A'}</td>
                <td className="px-6 py-4 align-middle">{item.owner}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  
      {/* Mobile Card View */}
      <div className="sm:hidden">
        <div className="space-y-4">
          {data.map((item) => (
            <div key={item.id} className="bg-white rounded-lg border border-gray-200 p-4">
              <div className="space-y-3">
                <div className="flex justify-between items-start">
                  <div>
                    <p className="text-sm text-gray-500 mb-1">Assessment ID</p>
                    <Link 
                      to={`/verify/${item.id}`}
                      state={{
                        orgid: routeParams.orgId,
                        wid: formData.ward_id,
                        asstid: item.id
                      }}
                      className="text-[#75d1e3] hover:text-[#5dbdd0] hover:underline text-lg font-medium"
                    >
                      {item.asst_ref}
                    </Link>
                  </div>
                  <div className="text-right">
                    <p className="text-sm text-gray-500 mb-1">Door Number</p>
                    <p className="text-gray-900">{item.door_no || 'N/A'}</p>
                  </div>
                </div>
                <div>
                  <p className="text-sm text-gray-500 mb-1">Owner Name</p>
                  <p className="text-gray-900">{item.owner}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  const handleAutocompleteSelection = async (selectedAssessmentNo) => {
    setAssessmentId(selectedAssessmentNo);
    setShowAutocomplete(false);
    setAssessmentSearchLoading(true);
  
    try {
      const wardId = formData.ward_id;
      // Find the assessment object that matches the selected number
      const selectedAssessment = wardAssessments[wardId].find(
        item => item.AssesmentNo === selectedAssessmentNo
      );
  
      if (selectedAssessment) {
        // Navigate using AssesmentID as asstid
        navigate(`/verify/${selectedAssessment.AssesmentID}`, {
          state: {
            orgid: routeParams.orgId,
            wid: wardId,
            asstid: selectedAssessment.AssesmentID // Changed from asstno to asstid and using AssesmentID
          }
        });
      } else {
        setAssessmentSearchError('Assessment not found');
      }
    } catch (error) {
      console.error('Error:', error);
      setAssessmentSearchError('Error processing selection');
    } finally {
      setAssessmentSearchLoading(false);
    }
  };
  


  return (
    <div className="min-h-screen bg-gray-50 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white shadow-xl rounded-2xl border border-gray-100">
          {/* Header Section */}
          <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center border-b border-gray-200 p-4 lg:p-6 space-y-4 lg:space-y-0">
            <div className="flex flex-col lg:flex-row items-start lg:items-center gap-4 lg:gap-8 w-full lg:w-auto">
              {/* Organization Name */}
              <div className="flex items-center gap-3 w-full lg:w-auto">
                <div className="p-2 bg-[#75d1e3]/10 rounded-lg">
                  <Building className="h-5 w-5 text-[#75d1e3]" />
                </div>
                <h3 className="text-lg font-semibold text-gray-800">{orgName}</h3>
              </div>
              
              {/* Ward Selection */}
              <div className="flex items-center gap-3 w-full lg:w-auto">
                <div className="p-2 bg-[#75d1e3]/10 rounded-lg"></div>
                <select
                  value={formData.ward_id}
                  onChange={handleWardChange}
                  className="w-full lg:w-48 px-3 py-2 bg-white border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#75d1e3] focus:border-[#75d1e3] transition-all duration-200 hover:border-[#75d1e3]"
                >
                  <option value="">Select Ward</option>
                  {userWards.map((ward, index) => (
                    <option key={`${ward.value}-${index}`} value={ward.value}>
                      {ward.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex items-center gap-3 w-full lg:w-auto">
  <div className="p-2 bg-[#75d1e3]/10 rounded-lg">
    <LayoutDashboard className="h-5 w-5 text-[#75d1e3]" />
  </div>
  <h3 className="text-lg font-semibold text-gray-800">
    {zoneName}
  </h3>
</div>
          </div>
  
          {/* Search Section */}
          <div className="p-4 lg:p-6">
            <div className="flex flex-col lg:flex-row items-start lg:items-end gap-4">
            <div className="w-full lg:w-64">
  <label htmlFor="assessment_id" className="block text-sm font-semibold text-gray-700 mb-1.5">
    Enter Assessment Number
  </label>
  <div className="relative">
    <input
      ref={assessmentInputRef}
      type="text"
      id="assessment_id"
      value={assessmentId}
      onChange={handleAssessmentIdChange}
      className={`w-full px-4 py-3.5 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#75d1e3] focus:border-[#75d1e3] transition-all duration-200 
        ${!formData.ward_id ? 'bg-gray-100 cursor-not-allowed' : 'hover:border-[#75d1e3]'}`}
      placeholder={orgCode ? `${orgCode}/024/111111` : "111/111/111111"}
      disabled={isInitialLoading || !formData.ward_id}
    />
    
    {/* Initial Loading Spinner */}
    {isInitialLoading && (
      <div className="absolute right-3 top-1/2 -translate-y-1/2">
        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-[#75d1e3]"></div>
      </div>
    )}
    
    {/* Autocomplete Loading Spinner */}
    {!isInitialLoading && autocompleteLoading && (
      <div className="absolute right-3 top-1/2 -translate-y-1/2">
        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-[#75d1e3]"></div>
      </div>
    )}
    
    {/* Autocomplete Results */}
    {showAutocomplete && !isInitialLoading && (
      <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-xl shadow-lg">
        {autocompleteResults.length > 0 ? (
          <ul className="py-1 max-h-60 overflow-auto">
            {autocompleteResults.map((result, index) => (
              <li
                key={index}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleAutocompleteSelection(result)}
              >
                {result}
              </li>
            ))}
          </ul>
        ) : (
          <div className="p-4 text-center text-gray-500">
            No matching assessment numbers found
          </div>
        )}
      </div>
    )}
  </div>
  {formErrors.assessmentId && (
    <p className="mt-1 text-red-500 text-sm flex items-center gap-1">
      <AlertCircle size={14} />
      {formErrors.assessmentId}
    </p>
  )}
</div>
  
              {/* OR Divider */}
              <div className="hidden lg:flex items-center px-4 h-[3.625rem]">
                <span className="text-gray-500 font-medium">OR</span>
              </div>
              <div className="flex lg:hidden items-center w-full justify-center">
                <span className="text-gray-500 font-medium">OR</span>
              </div>
  
{/* Street Selection */}
<div className="w-full lg:w-64">
  <label htmlFor="street_id" className="block text-sm font-semibold text-gray-700 mb-1.5">
    Street Name
  </label>
  <div className="relative">
    <Home className={`absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 ${!formData.ward_id ? 'text-gray-400' : 'text-[#75d1e3]'}`} />
    <select
      id="street_id"
      value={formData.street_id}
      onChange={handleStreetChange}
      className={`w-full pl-10 pr-10 py-3.5 bg-white border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#75d1e3] focus:border-[#75d1e3] transition-all duration-200 appearance-none
        ${!formData.ward_id ? 'bg-gray-100 cursor-not-allowed border-gray-200' : 'hover:border-[#75d1e3]'}`}
      disabled={!formData.ward_id || streetLoading}
    >
      <option value="">Select Street</option>
      {userStreets.map((street, index) => (
        <option key={`${street.value}-${index}`} value={street.value}>
          {street.label}
        </option>
      ))}
    </select>
    <ChevronDown className={`absolute right-3 top-1/2 -translate-y-1/2 h-5 w-5 ${!formData.ward_id ? 'text-gray-400' : 'text-gray-400'}`} />
  </div>
  {(streetError || formErrors.street_id) && (
    <p className="mt-1 text-red-500 text-sm flex items-center gap-1">
      <AlertCircle size={14} />
      <span>{streetError || formErrors.street_id}</span>
    </p>
  )}
</div>
  
              {/* Search Button */}
              <button
                onClick={handleWardStreetSearch}
                className="w-full lg:w-auto px-6 py-3.5 bg-[#75d1e3] text-white rounded-xl hover:bg-[#5dbdd0] transition-all duration-200 flex items-center justify-center gap-2 h-[3.625rem] lg:ml-4"
                disabled={wardStreetSearchLoading}
              >
                {wardStreetSearchLoading ? (
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                ) : (
                  <Search className="h-5 w-5" />
                )}
                <span>Search</span>
              </button>
            </div>
          </div>
        </div>
  
        {/* Error Messages */}
        {assessmentSearchError && (
          <div className="bg-white shadow-md rounded-lg mt-6 mb-8">
            <div className="p-6 text-center text-red-500 flex items-center justify-center gap-2">
              <AlertCircle size={20} />
              <span className="font-medium">{assessmentSearchError}</span>
            </div>
          </div>
        )}
  
  {((searchResults.length > 0 || activeTab === 'completed') || searchError) && (
  <div className="bg-white shadow-md rounded-lg mt-6 mb-8">
    <div className="border-b border-gray-200">
      <div className="flex flex-wrap">
        <Tab 
          active={activeTab === 'pending'} 
          icon={ClipboardList}
          label="Pending"
          onClick={() => handleTabChange('pending')}
        />
        <Tab 
          active={activeTab === 'completed'} 
          icon={CheckCircle}
          label="Completed"
          onClick={() => handleTabChange('completed')}
        />
      </div>
    </div>
    {searchError ? (
      <div className="p-6 text-center text-red-500">
        {searchError}
      </div>
    ) : (
      <div className="sm:overflow-x-auto">
        <div className="sm:min-w-[800px] p-4 sm:p-6">
          {activeTab === 'pending' ? (
            wardStreetSearchLoading ? (
              <div className="flex justify-center items-center py-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#75d1e3]"></div>
              </div>
            ) : (
              renderTable(searchResults)
            )
          ) : (
            completedDataLoading ? (
              <div className="flex justify-center items-center py-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#75d1e3]"></div>
              </div>
            ) : (
              renderTable(completedData)
            )
          )}
        </div>
      </div>
    )}
  </div>
)}
  
        {/* No Results Messages */}
        {searchResults.length === 0 && hasSearched && !assessmentSearchLoading && 
         !wardStreetSearchLoading && activeTab === 'pending' && !searchError && (
          <div className="bg-white p-6 text-center rounded-lg shadow-md mt-6 mb-8">
            <p className="text-gray-600">No pending properties found for the selected criteria.</p>
          </div>
        )}
  
        {completedData.length === 0 && hasSearched && !completedDataLoading && 
         activeTab === 'completed' && !searchError && (
          <div className="bg-white p-6 text-center rounded-lg shadow-md mt-6 mb-8">
            <p className="text-gray-600">No completed properties found for the selected criteria.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SurveyForm;